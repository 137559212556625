import $ from "jquery";

/**
 * Implémentation JavaScript des animations basées sur le défilement pour le composant hero-banner
 * Utilisant l'événement de défilement pour gérer les animations
 * sur les navigateurs ne supportant pas nativement l'animation-timeline CSS
 * Les navigateurs supportant cette fonctionnalité utiliseront la version CSS native,
 * tandis que les autres utiliseront cette implémentation JS
 *
 * Cette implémentation respecte la préférence utilisateur prefers-reduced-motion
 * pour une meilleure accessibilité
 */

import { logFactory, MediaQueryHelper } from "GlobalSite";

const { getBreakPointValue, lesserThan, on } = MediaQueryHelper;
const breakPointValueL = getBreakPointValue("l");

// Configuration par défaut du composant
const DEFAULT_CONFIG = {
  // Sélecteurs
  componentSelector: ".comp-hero-banner",
  imageElementsSelector: "div[class*='comp-hero-banner__img-']",

  // Classes des directions d'animation
  directionClasses: {
    top: "comp-hero-banner__img-top",
    left: "comp-hero-banner__img-left",
    right: "comp-hero-banner__img-right",
  },

  // Paramètres d'animation
  animationRange: 300, // en pixels
  transitionDuration: 400, // en ms
  transitionTiming: "linear",
  amplificationFactor: 1.0,
};

// Création du logger avec nom du composant paramétrable
const compName = "comp_aem_hero-banner";
const Log = logFactory(compName);
const { log, error } = {
  log: Log.log.bind(Log),
  error: Log.error.bind(Log),
};

log({ breakPointValueL });

// Contrôle si les animations sont actives ou non
let isActivated = true;

/**
 * Désactive les animations
 */
function deactivate() {
  if (isActivated) {
    isActivated = false;
    log("Animations désactivées");
  }
}

/**
 * Active les animations
 */
function activate() {
  if (!isActivated) {
    isActivated = true;
    log("Animations activées");
  }
}

/**
 * Vérifie si les animations sont activées
 * @return {boolean} true si les animations sont activées
 */
function isActive() {
  return isActivated;
}

log("-->");

/**
 * Détecte si l'utilisateur préfère réduire les animations
 * @returns {boolean} true si l'utilisateur préfère réduire les animations
 */
function prefersReducedMotion() {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-reduced-motion: reduce)").matches
  );
}

/**
 * Détecte si le navigateur supporte nativement les animations pilotées par le défilement
 * @returns {boolean} true si le navigateur supporte les animations basées sur le défilement
 */
function supportsScrollDrivenAnimations() {
  // Vérifier le support via CSS.supports
  return !!(
    typeof CSS !== "undefined" &&
    CSS.supports &&
    (CSS.supports("animation-timeline: scroll()") ||
      CSS.supports("animation-timeline: scroll(block)") ||
      CSS.supports("scroll-timeline-name"))
  );
}

/**
 * Initializes scroll-driven animations for specified elements.
 *
 * @param {Object} [config={}] An optional configuration object for customizing the behavior of the scroll-driven animations.
 * @param {string} [config.imageElementsSelector] jQuery selector for the image elements to animate.
 * @param {string} [config.componentSelector] jQuery selector for the component wrapping the image elements.
 * @param {number} [config.amplificationFactor] Amplification factor determining the intensity of the animation.
 * @param {number} [config.transitionDuration] Duration of the animation's transition, in milliseconds.
 * @param {string} [config.transitionTiming] Timing function used for the CSS transition effect.
 * @param {number} [config.animationRange] Scroll range within which the animations will occur.
 * @param {Object} [config.directionClasses] Mapping of direction classes to define animation type and direction.
 * @param {string} [config.directionClasses.top] Class name for top direction animation.
 * @param {string} [config.directionClasses.left] Class name for left direction animation.
 * @param {string} [config.directionClasses.right] Class name for right direction animation.
 *
 * @return {Object} A cleanup object with a `destroy` function to remove event listeners and observers.
 */
export function initScrollDrivenAnimations(config = {}) {
  // Fusion des configurations par défaut et personnalisées
  const settings = { ...DEFAULT_CONFIG, ...config };

  // Sélection des éléments avec les sélecteurs configurés
  const $imgElements = $(settings.imageElementsSelector);
  const $component = $(settings.componentSelector).first();

  // Si aucun élément n'est trouvé, on sort immédiatement
  if ($imgElements.length === 0) {
    log("Aucun élément d'image trouvé, animations non initialisées");
    return;
  }

  // Vérifier si les animations sont activées pour ce composant
  if (!isActivated) {
    log("Animations désactivées");
    $component.addClass("animations-disabled");
    return;
  }

  // Vérifier si l'utilisateur préfère réduire les animations
  const reducedMotionPreferred = prefersReducedMotion();

  // Si l'utilisateur préfère réduire les animations, appliquer la classe appropriée et sortir
  if (reducedMotionPreferred) {
    $component.addClass("reduced-motion");
    return;
  }

  // Détecter si le navigateur supporte nativement les animations basées sur le défilement
  const hasNativeSupport = supportsScrollDrivenAnimations();

  // Si le navigateur supporte nativement les animations, on utilise la version CSS native et on sort
  $component.addClass(
    hasNativeSupport
      ? "native-scroll-animations"
      : "polyfill-scroll-animations",
  );
  if (hasNativeSupport) {
    return;
  }

  // Configuration pour chaque élément d'image
  $imgElements.each(function () {
    const $imgElement = $(this);
    const className = this.className;

    try {
      // Détermine la direction de l'animation en fonction de la classe
      let cssTransform;
      let direction;

      // Conversion du facteur d'amplification en pourcentage
      const amplificationPercent = Math.round(
        settings.amplificationFactor * 100,
      );

      const transformMap = {
        [settings.directionClasses.top]: {
          direction: -1,
          cssTransform: "translateY",
        },
        [settings.directionClasses.left]: {
          direction: -1,
          cssTransform: "translateX",
        },
        [settings.directionClasses.right]: {
          direction: 1,
          cssTransform: "translateX",
        },
      };

      const transformSettings = transformMap[className];

      if (transformSettings?.direction && transformSettings?.cssTransform) {
        ({ direction, cssTransform } = transformSettings);
      } else {
        return; // Si l'élément n'a pas l'une des classes ciblées, on passe au suivant
      }

      // Appliquer la transition avec les paramètres configurés
      $imgElement.css({
        willChange: "transform",
        transform: "translate(0, 0)", // Position initiale explicite
        transition: `transform ${settings.transitionDuration}ms ${settings.transitionTiming}`,
      });

      // Stocker les informations de transformation dans des attributs data
      $imgElement.attr({
        "data-transform-type": cssTransform,
        "data-direction": direction,
        "data-end-value": amplificationPercent,
      });

      // Ajoute une classe pour identifier les éléments animés
      $imgElement.addClass("io-animated");
    } catch (err) {
      error({
        [`Erreur lors de la configuration de l'animation pour ${className}:`]:
          err,
      });
    }
  });

  // Fonction pour calculer et appliquer la transformation basée sur la position de défilement
  function updateElementTransforms(scrollPosition) {
    // Calculer le pourcentage d'animation basé sur la position de défilement
    const progress = Math.min(
      Math.max(scrollPosition / settings.animationRange, 0),
      1,
    );

    // Appliquer la transformation à chaque élément
    $imgElements.each(function () {
      const $element = $(this);

      // Récupérer les données de transformation stockées dans les attributs
      const transformType = $element.attr("data-transform-type");
      const direction = parseInt($element.attr("data-direction"), 10);
      const endValue = parseInt($element.attr("data-end-value"), 10);

      if (!transformType || isNaN(direction) || isNaN(endValue)) {
        return; // Données manquantes ou invalides
      }

      // Calcul de la valeur de transformation actuelle
      const transformValue = direction * endValue * progress;

      // Application de la transformation
      $element.css("transform", `${transformType}(${transformValue}%)`);
    });
  }

  // Appliquer l'animation immédiatement au chargement
  updateElementTransforms(0);

  // Utilisation de IntersectionObserver pour une meilleure performance
  const targetElement = $component[0];
  let lastScrollY = 0;

  // Fonction qui sera appelée quand l'élément est visible
  const handleIntersection = (entries) => {
    // Si l'élément est visible
    if (entries[0].isIntersecting) {
      // Activer l'animation au défilement
      window.addEventListener("scroll", onScroll, { passive: true });
      // Appliquer immédiatement pour la position actuelle
      onScroll();
    } else {
      // Désactiver l'animation quand l'élément n'est plus visible
      window.removeEventListener("scroll", onScroll);
    }
  };

  function onScroll() {
    // N'exécuter que si les animations sont activées
    if (!isActivated) {
      return;
    }

    lastScrollY = window.scrollY || $(window).scrollTop() || 0;

    // N'exécuter que si on est dans la plage d'animation
    if (lastScrollY <= settings.animationRange) {
      window.requestAnimationFrame(() => updateElementTransforms(lastScrollY));
    }
  }

  // Créer l'observer
  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.1, // Ajuster selon vos besoins
  });

  // Observer l'élément
  if (targetElement) {
    if (isActivated) {
      observer.observe(targetElement);
      log("Observation de l'élément activée");
    } else {
      log("Observation non démarrée - animations désactivées");
    }
  } else {
    error("Élément cible non trouvé pour l'observation");
  }

  // Retourner une interface simplifiée pour contrôler les animations
  return {
    destroy: () => {
      // Nettoyer les événements et observer
      window.removeEventListener("scroll", onScroll);
      observer.disconnect();

      // Réinitialiser les styles des éléments animés
      $imgElements
        .css({
          willChange: "",
          transform: "",
          transition: "",
        })
        .removeClass("io-animated");

      $component.removeClass("animations-disabled");

      log("Ressources du composant nettoyées");
    },
    // Activation des animations
    start: () => {
      activate();
      if (targetElement) {
        observer.observe(targetElement);
      }
    },
    // Désactivation des animations
    stop: () => {
      deactivate();

      // Réinitialiser toutes les images à leur état initial
      $imgElements.each(function () {
        const $element = $(this);
        // Réinitialiser la transformation à zéro
        $element.css({
          transform: "translate(0, 0)",
          transition: `transform ${settings.transitionDuration}ms ${settings.transitionTiming}`,
        });
      });

      // Déconnecter l'observateur et supprimer les écouteurs d'événements
      observer.disconnect();
      window.removeEventListener("scroll", onScroll);

      log("Animation désactivée, images réinitialisées");
    },
    // Vérification de l'état
    isActive,
  };
}

/**
 * Fonction d'initialisation principale du composant
 * @param {Object} config - Configuration personnalisée optionnelle
 */
function init(config = {}) {
  // Fusion des configurations
  const settings = { ...DEFAULT_CONFIG, ...config };

  // Initialise les animations pilotées par le défilement
  const instance = initScrollDrivenAnimations(settings);

  // Ajouter un écouteur pour détecter les changements de préférence pour la réduction des animations
  if (window.matchMedia) {
    const motionMediaQuery = window.matchMedia(
      "(prefers-reduced-motion: reduce)",
    );

    // Définir une fonction de rappel pour gérer les changements de préférence
    const handleMotionPreferenceChange = () => {
      // Réinitialiser les animations si nécessaire
      if (instance && instance.destroy) {
        instance.destroy();
      }
      // Réinitialiser avec les paramètres actuels
      return initScrollDrivenAnimations(settings);
    };

    // Utiliser addEventListener pour la gestion des événements de changement de préférence
    motionMediaQuery.addEventListener("change", handleMotionPreferenceChange);
  }

  on(lesserThan("l", { strict: true }), {
    success() {
      // Sortir si déjà désactivé ou pas d'instance à manipuler
      if (!isActivated || !instance) {
        return;
      }

      log(`Breakpoint < ${breakPointValueL}px : Désactivation des animations`);

      // Utiliser l'API la plus moderne disponible
      const { stop, destroy } = instance;

      if (stop) {
        // La méthode stop() contient déjà la logique de réinitialisation des images
        stop();
      } else if (destroy) {
        // Fallback pour les anciennes versions
        deactivate();

        // Réinitialiser manuellement les images
        $(".comp_aem_hero-banner").find(".io-animated").css({
          transform: "translate(0, 0)",
          transition: "",
        });

        destroy();
        initScrollDrivenAnimations(settings);
      }
    },
    failed() {
      // Sortir si déjà activé
      if (isActivated) {
        return;
      }

      log(`Breakpoint ≥ ${breakPointValueL}px : Réactivation des animations`);

      // Destructuration conditionnelle avec valeurs par défaut
      const { start, destroy } = instance || {};

      if (start) {
        start();
      } else if (destroy) {
        activate();
        destroy();
        initScrollDrivenAnimations(settings);
      } else {
        // Aucune instance ou API incomplète
        activate();
        initScrollDrivenAnimations(settings);
      }
    },
  });

  return instance;
}

// Utilisation de jQuery ready pour exécuter l'initialisation
$(function domReady() {
  // Initialisation avec la configuration par défaut
  init();
});

// Export pour une utilisation externe avec possibilité de configuration personnalisée
export { init };
